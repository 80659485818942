// import Swiper from 'swiper/bundle';
import Swiper, { Navigation } from 'swiper';

// instructions
// just call this module in your module
// give it your modules DOM as an argument
// add data-overlay-slider="slide" to all the DOM elements, which will be
// slides on the overlay
// add data-overlay-slider="zoom" to all the buttons which will open the overlay
// call the plugin like this: new OverlaySlider($myModule);

class OverlaySlider {
    constructor (el) {
        this.$module = el; // this is the module, which has the images
        this.$overlay = null;
        this.$slider = null;
        this.$slides = this.$module.querySelectorAll('[data-overlay-slider="slide"]');
        this.$zoomButtons = this.$module.querySelectorAll('[data-overlay-slider="zoom"]');
        this.init();
    }

    init () {
        this.setEvents();
    }

    setEvents () {
        const addEvent = ($button) => {
            $button.addEventListener('click', (e) => {
                e.preventDefault();

                if (this.$overlay) {
                    this.toggleOverlay(this);
                } else {
                    this.createOverlay();
                    this.initSlider();
                    this.bindCloseButton();
                    this.checkArrows();
                }
                const clickedElementIndex = this.getButtonIndex($button);
                this.slider.slideTo(clickedElementIndex, 0);
            });
        };

        for (let i = 0; i < this.$zoomButtons.length; i++) {
            const $button = this.$zoomButtons[i];
            addEvent($button);
        }
    }

    bindCloseButton () {
        this.$close = this.$overlay.querySelector('[data-overlay-slider="close"]');
        this.$slide = this.$overlay.querySelector('.swiper-wrapper');
        this.$close.addEventListener('click', () => {
            this.toggleOverlay(this);
        });
        this.$slide.addEventListener('click', () => {
            this.toggleOverlay(this);
        });
    }

    initSlider () {
        this.slider = new Swiper(this.$slider, {
            modules: [Navigation],
            speed: 800,
            resistance: true,
            resistanceRatio: 0,
            allowTouchMove: false,
            navigation: {
                prevEl: this.$slider.querySelector('[data-overlay-slider="prev"]'),
                nextEl: this.$slider.querySelector('[data-overlay-slider="next"]')
            }
        });
    }

    createOverlay () {
        let slideMarkup = '';

        for (let i = 0; i < this.$slides.length; i++) {
            const $slide = this.$slides[i];

            const $zoom = $slide.querySelector('[data-overlay-slider="zoom"]');
            const zoomUri = $zoom.href;

            if (zoomUri !== '') {
                slideMarkup += '<div class="swiper-slide overlay-slider__slide"><figure class="overlay-slider__image"><span><i><img src="' + zoomUri + '" /></i></span></figure></div>';
            }
        }

        const $overlaySlider = document.createElement('DIV');
        $overlaySlider.classList.add('overlay-slider');
        $overlaySlider.innerHTML =
            '<button class="overlay-slider__close action action--close" data-overlay-slider="close"></button>' +
            '<div class="swiper-wrapper">' +
                slideMarkup +
            '</div>' +
            '<button class="overlay-slider__prev action action--prev" data-overlay-slider="prev"></button>' +
            '<button class="overlay-slider__next action action--next" data-overlay-slider="next"></button>';

        this.$slider = $overlaySlider;
        this.$module.appendChild($overlaySlider);

        setTimeout(() => {
            $overlaySlider.classList.add('overlay-slider--visible');
        }, 50);
        this.$overlay = $overlaySlider;
    }

    toggleOverlay () {
        if (this.$overlay === null) {
            return false;
        }

        if (this.$overlay.classList.contains('overlay-slider--visible')) {
            this.$overlay.classList.remove('overlay-slider--visible');
        } else {
            this.$overlay.classList.add('overlay-slider--visible');
        }
    }

    getButtonIndex ($button) {
        for (let i = 0; i < this.$zoomButtons.length; i++) {
            const $curButton = this.$zoomButtons[i];

            if ($curButton === $button) {
                return i;
            }
        }

        return 0;
    }

    checkArrows () {
        if (this.$slides.length <= 1) {
            this.$slider.querySelector('[data-overlay-slider="prev"]').classList.add('hidden');
            this.$slider.querySelector('[data-overlay-slider="next"]').classList.add('hidden');
        }
    }
}

export { OverlaySlider };
