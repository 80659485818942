import './image-gallery.scss';
import { Slider } from '../slider/slider';
import { OverlaySlider } from '../../utilities/js/overlay-slider';

class ImageGallery {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-image-gallery',
            offset: 50,
            prevnext: true,
            breakpoints: null,
            slidesPerView: 'auto',
            slidesPerGroup: 1
        };

        this.settings = Object.assign({}, defaults, options);
        this.$ImageGallery = element;
    }

    initialize () {
        this.initImageSlider(this.$ImageGallery);
        this.initOverlay(this.$ImageGallery);
    }

    initImageSlider (slider) {
        this.slider = new Slider(slider, {
            crossfade: false,
            calculateHeight: false,
            initAttr: this.settings.initAttr,
            slidesPerView: this.settings.slidesPerView,
            parallax: true,
            prevnext: this.settings.prevnext,
            speed: 750
        });
    }

    initOverlay (gallery) {
        // init overlay slider
        // eslint-disable-next-line no-new
        new OverlaySlider(gallery);
    }
}

export { ImageGallery };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$ImageGallery = $context.querySelectorAll('[data-image-gallery="root"]');
        for (let i = 0; i < $$ImageGallery.length; i++) {
            const $ImageGallery = new ImageGallery($$ImageGallery[i]);
            $ImageGallery.initialize();
        }
    }
});
